import * as React from "react"

import Layout from "../components/Layout"
// import Seo from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    {/* <Seo title="404" /> */}
    <h1>404: Page Not Found</h1>
    <p>Oops! It looks like we sent you the wrong way. Let us guide you back to our home space.</p>
  </Layout>
)

export default NotFoundPage
